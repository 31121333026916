@import url(https://fonts.googleapis.com/css2?family=Caveat&family=Nunito:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css?family=Caveat+Brush);
* {
  --cream: #fffff6;
  --dkCream: #f2f2e9;
  --dkGray: #4c4c49;
  --green: #6ac66b;
  --blue: #549bea;
  --orange: #ff914d;
  --yellow: #ffbd59;
}

html {
  background-color: var(--cream);
}

body {
  position: relative;
  min-height: 100vh;
}

.content {
  background-color: var(--cream);
  width: 100%;
  min-height: 87vh;
}

.dk-cream-bg {
  background-color: var(--dkCream);
  min-height: 87vh;
  padding-top: 1vw;
  padding-bottom: 1vw;
  width: 100%;
}

footer {
  background-color: var(--cream);
  font-family: "Nunito";
  height: 50px;
  text-align: center;
  padding-top: 15px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.navbar {
  border-bottom: 4px solid var(--orange);
  background-color: var(--cream);
  padding-bottom: 15px;
}

#navbarTogglerDemo02 {
  padding-top: 20px;
}

h1,
.vbb-header {
  font-family: "Caveat Brush", cursive;
  font-style: italic;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  letter-spacing: 3px;
  font-size: 3em;
  color: var(--dkGray);
}

h2 {
  font-size: 25px;
}

h3 {
  font-size: 23px;
  padding-left: 25px;
}

h5 {
  font-family: "Caveat Brush", cursive;
  color: var(--dkGray);
  font-size: 40px;
  padding-top: 30px;
}

p {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  padding-left: 50px;
}

a {
  color: var(--blue);
}

.badge {
  background-color: var(--green);
  margin-left: 20px;
  font-size: medium;
  font-weight: 900;
  font-family: "Nunito", cursive;
}

#bar-header {
  font-weight: bold;
  color: var(--dkGray);
  margin-bottom: -25px;
}

#bar-header:hover {
  color: var(--blue);
}

#bar-header-link:hover {
  color: transparent;
}

#welcome-header {
  font-size: 60px;
}

#portal-purpose {
  color: var(--blue);
  font-family: "Nunito";
  font-weight: 900;
  padding-top: 5px;
}

/* BUTTONS */

.btn {
  font-family: "Caveat Brush", cursive;
  font-style: italic;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 22px;
  color: white;
  border-radius: 18px;
  padding: 8px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-appearance: none;
}

.btn:hover {
  background-color: var(--dkGray);
  color: #dddddd;
}

.btn-pair a:first-child {
  margin-right: 20px;
}

.dashboard-btn {
  width: 275px;
  min-width: 260px;
  font-family: "Nunito", cursive;
  font-weight: bold;
  font-size: 20px;
  color: white;
  letter-spacing: normal;
}

.signin-btn,
#requestsession-btn,
.book-btn,
.l3-btn,
.l8-btn {
  background-color: var(--green);
}

.donate-btn,
.gcal-btn,
.register-btn,
.l1-btn,
.l6-btn,
.next-btn {
  background-color: var(--blue);
}

.signout-btn,
.l2-btn,
.l5-btn,
.unbook-btn {
  background-color: var(--orange);
}

.l4-btn,
.l7-btn {
  background-color: var(--yellow);
}

.goback-btn,
.prev-btn {
  background-color: gray;
}

.goback-btn {
  margin-right: 20px;
}

.signin-btn:hover,
.register-btn:hover {
  background-color: var(--orange);
}

.unbook-btn:hover {
  background-color: red;
}

hr {
  border: 0.7px solid gainsboro;
}

#sep-bar {
  width: 85%;
}

.twocol-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  -webkit-column-count: 2;
          column-count: 2;
}

.column {
  flex: 2 1;
  width: 100%;
  margin: 2vw;
  padding: 30px;
  border-radius: 30px;
}

.col-card {
  background-color: var(--cream);
  min-height: 675px;
}

.btns a:first-child {
  margin-top: 20px;
  font-size: 20px;
}

.btns a:last-child {
  margin-top: 5px;
  margin-bottom: 30px;
}

.questions-notice {
  padding: 20px;
  padding-left: 40px;
  max-width: 900px;
}

#mentoring-session-box {
  flex: 1 1;
}

.session-details-link {
  font-size: x-large;
  font-weight: 500;
}

.session-details-location {
  color: var(--blue);
  padding-left: 5px;
}

.session-details-card {
  width: 50vw;
  margin-top: 20px;
  margin-bottom: 70px;
}

#mentoring-resources-box {
  max-width: 1000px;
}

#mentoring-resources-fields {
  flex-direction: row;
}

.dashboard-btn {
  margin: 20px;
}

#signin-box {
  text-align: center;
  position: relative;
  bottom: 65px;
}

#google-box {
  border-radius: 10px;
  max-width: 362px;
  margin: auto;
}

#create-account-box {
  border-radius: 10px;
  padding: 20px;
  max-width: 450px;
  margin: auto;
}

#create-account-box p {
  padding-left: 0;
  font-size: 20px;
  color: var(--orange);
  font-weight: bolder;
}

#signin-picture-box {
  margin: 5vw;
  max-width: 800px;
}

#signin-picture {
  width: 600px;
  margin: 5vw;
}

.loading-spinner {
  margin-top: 50%;
  margin-left: 50%;
}

#full-logo {
  display: block;
}

#full-logo img {
  width: 200px;
  margin-left: 30px;
}

#mini-logo {
  display: none;
}

#mini-logo img {
  width: 45px;
}

/* BOOKING */

.no-bookings {
  font-size: 22px;
  font-weight: 400;
  font-style: italic;
  text-indent: 0px;
  color: var(--orange);
}

.make-booking-header {
  text-indent: 0px;
  font-style: italic;
  color: var(--green);
}

#booking-box {
  width: 550px;
  margin: 50px;
  margin-top: 20px;
  margin-bottom: 100px;
}

.label-select-box {
  margin: 1rem 0;
}

select {
  float: right;
  width: 220px;
  margin-top: 10px;
  height: 30px;
}

label {
  font-size: large;
  font-weight: 500;
  max-width: 90%;
  margin-top: 10px;
}

input {
  height: 30px;
  margin-bottom: 10px;
}

#mentor,
#commitment {
  float: right;
  margin-top: 10px;
}

#register-header {
  padding: 20px;
}

.step-form {
  padding: 20px;
  max-width: 700px;
  margin: auto;
  height: 100%;
}

.success-step {
  display: flex;
  flex-direction: column;
}

.register-form {
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 120px;
}

/* DESKTOP ADJUSTMENTS */

@media all and (min-width: 990px) {
  #bar-header {
    margin-left: 20vw;
    width: 300px;
  }

  #register-btn {
    margin-left: 5vw;
  }

  .btn-pair {
    position: absolute;
    right: 40px;
    top: 20px;
  }

  #booking-header {
    font-size: 40px;
    padding-top: 30px;
  }

  #signin-box {
    min-width: 400px;
    margin-top: 150px;
  }

  #mentoring-session-box {
    min-width: 450px;
  }
  #mentoring-resources-box {
    min-width: 700px;
  }
}

/* JUST TABLET ADJUSTMENTS */

@media only screen and (min-width: 660px) and (max-width: 1270px) {
  #mentoring-session-box,
  #mentoring-resources-box {
    min-width: 700px;
    max-width: 1000px;
  }
}

/* TABLET AND MOBILE ADJUSTMENTS */

@media (max-width: 990px) {
  #bar-header {
    margin-top: -5px;
  }

  #booking-header {
    font-size: 30px;
  }

  .badge {
    font-size: small;
    margin-left: 10px;
  }

  .column {
    margin: 5vw;
    min-width: 350px;
  }

  #signin-picture {
    display: none;
  }

  #booking-picture {
    display: none;
  }

  .vbb-header {
    font-size: 2.5em;
  }

  #signin-box {
    top: 10px;
  }

  #mentoring-session-box,
  #mentoring-resources-box {
    margin: 20px;
  }

  #booking-box {
    padding: 10px;
    margin-top: 40px;
  }

  .session-details-card {
    width: 85vw;
  }
}

/* MOBILE ADJUSTMENTS */

@media (max-width: 600px) {
  #full-logo {
    display: none;
  }

  #mini-logo {
    display: block;
  }

  footer {
    font-size: 13px;
  }

  p {
    font-size: 16px;
  }

  h1 {
    font-size: 35px;
  }

  #bar-header {
    margin-top: -20px;
    font-size: 40px;
  }

  select {
    width: 80%;
  }

  #welcome-header {
    width: 270px;
    margin: auto;
  }

  #register-header {
    width: 350px;
  }

  .goback-btn {
    margin-right: 100px;
    margin-bottom: 20px;
  }

  .dashboard-btn {
    font-size: 18px;
  }

  #booking-box {
    width: 350px;
  }

  .register-form {
    padding-left: 20px;
    padding-right: 20px;
  }

  #mentoring-resources-box {
    min-height: 850px;
  }

  #time-zone {
    margin-left: 90px;
  }
}

/* SUPER MOBILE ADJUSTMENTS */

@media (max-width: 355px) {
  .badge {
    display: none;
  }
}

